import request from "@/utils/request";

/**
 * @description: 获取所有区域名字及其对应编号
 * @param {*} query
 */
export function getWaterUnit(id) {
  return request({
    url: "/api/WaterUnit/Get",
    method: "get",
    params: { id },
  });
}
