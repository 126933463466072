//用水性质
export let useWaterType = {
  0: "生活用水",
  1: "商业用水",
  2: "综合用水",
  3: "定额用水",
  4: "特殊用水",
};

//计费方式
export let billingType = {
  0: "按量计费",
  1: "定额计费",
};

//计费周期
export let billingCycleType = {
  0: "季度",
  1: "半年",
  2: "全年",
};

export let authUrl =
  "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx80ba6e32d752fa2f&redirect_uri=http://wechat.xjjtgps.com&response_type=code&scope=snsapi_base&state=ok#wechat_redirect";
